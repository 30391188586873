import {http, param} from '../utils/http'
import conf from '../config'

export const API_ROOT = `${conf.API_URL}`
export const SECRET_KEY = `${conf.SECRET_KEY}`
const accountServices = {
    verifyAccount(payload) {
        return http.post(`${API_ROOT}/account/verify-account`, payload, { requiredAuth: false } ).then(resp => {
            return resp
        })
    },
    forgotPassword(payload) {
        return http.post(`${API_ROOT}/account/forgot-password`, payload, { requiredAuth: false } ).then(resp => {
            return resp
        })
    },
    getUser(id) {

    },
    resendCode(payload) {
        return http.post(`${API_ROOT}/account/resendcode`,payload, { requiredAuth: false } ).then(resp => {
            return resp
        })
    },
    resendMfsCode(payload) {
        return http.post(`${API_ROOT}/account/resendmfa`,payload, { requiredAuth: false } ).then(resp => {
            return resp
        })
    },
    verifyCode(payload) {
        return http.post(`${API_ROOT}/account/confirmcode`,payload, { requiredAuth: false } ).then(resp => {
            return resp
        })
    },
    resetPassword(payload) {
        return http.post(`${API_ROOT}/account/reset-password`, payload, { requiredAuth: false } ).then(resp => {
            return resp
        })
    },
    recaptcha(token) {
        return http.get(`${API_ROOT}/account/ValidateAsync?response=${token}&secret=${SECRET_KEY}`).then(resp => {
            return resp
        })
    },
}

export default accountServices
