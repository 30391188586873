import accountServices from '../../../services/account'
import { resolve } from 'core-js/fn/promise'
export default {
    data () {
        return {
            email: ''
        }
    },
    methods: {
        async onSubmitLogin(){
            const isValid = await this.$refs['observer'].validate()
            if (!isValid) {
                this._showToast('Please check your information', {variant: 'warning'})
            } else {
                accountServices.forgotPassword({email: this.email}).then(resp => {
                    if (!resp.error) {
                        this.$router.push({name: 'SuccessPage', params: {
                            title: 'Sent successfully!',
                            subTitle: 'Kindly check your email for further action',
                            icon: 'far fa-check-circle'
                        }})
                    }
                    else
                    {
                        this._showToast(resp.error.message, { variant: 'warning' })
                    }
                })
            }
        },
    }
}